@media (max-width: 80em) {
  .hero {
    max-width: 120rem;
  }
  .heading-primary {
    font-size: 4.4rem;
  }
}

@media (max-width: 72em) {
  html {
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6rem;
  }

  .heading-secondary {
    font-size: 3.6rem;
  }
  .heading-tertiary {
    font-size: 2.4rem;
  }
  .header {
    padding: 0 3.2rem;
  }
  .main-nav-list {
    gap: 3.2rem;
  }
  .hero {
    gap: 4.4rem;
  }
}

@media (max-width: 58em) {
  html {
    font-size: 50%;
  }

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    gap: 6.4rem;
  }

  .hero-text-box,
  .hero-img-box {
    text-align: center;
  }

  .hero-img {
    width: 60%;
  }

  .hero-img-box {
    display: flex;
    justify-content: center;
  }

  .grid {
    row-gap: 0.1rem;
  }

  .step-number {
    font-size: 7.4rem;
  }

  .btn-mobile-nav {
    display: block;
    z-index: 1000;
  }

  .main-nav {
    background-color: rgba(255, 255, 255, 0.97);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transform: translateY(-100%);

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;

    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }

  .nav-open .main-nav {
    opacity: 1;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
  }

  .nav-open .icon-mobile-nav[name="close-outline"] {
    display: block;
  }

  .nav-open .icon-mobile-nav[name="menu-outline"] {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
  }

  .main-nav-link {
    font-size: 3rem;
  }

  .btn {
    padding: 1.2rem 1.6rem;
  }
}

@media (max-width: 47em) {
  .grid--3-cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .member:last-child {
    grid-column: 1/-1;
    width: 50%;
    justify-self: center;
    margin-top: 2rem;
  }

  .last-element {
    grid-column: 1/-1;
    width: 50%;
    justify-self: center;
  }
}

@media (max-width: 34em) {
  .grid--2-cols,
  .grid--3-cols {
    grid-template-columns: 1fr;
  }

  .hero {
    padding: 0 3.2rem;
  }

  .btn {
    padding: 2.4rem 1.6rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero-img {
    width: 80%;
  }

  .step-img-box:nth-child(1) {
    grid-row: 2;
  }

  .step-img-box:nth-child(5) {
    grid-row: 6;
  }

  .step-img-box {
    transform: translateY(-2rem);
  }

  .section-how {
    padding: 9.6rem 0 0 0;
  }

  .member {
    width: 70%;
    justify-self: center;
    margin-top: 2rem;
  }

  .member:last-child {
    width: 70%;
  }

  .feature {
    grid-column: 1/-1;
    width: 50%;
    justify-self: center;
  }

  .randl {
    grid-template-columns: 1fr;
  }

  .register-img-box {
    height: 30rem;
    grid-row: 1;
  }

  .login-img-box {
    height: 30rem;
    grid-row: 1;
  }

  .randl-text-box {
    padding: 3.2rem;
  }
}
