* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
  font-family: "Roboto Slab", sans-serif;
  overflow-x: hidden;
}

.container {
  max-width: 120rem;
  padding: 0 3.2rem;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #8296b8;

  height: 9.8rem;
  padding: 0 4.8rem;
  position: relative;
}

.logo {
  height: 8rem;
  border: 2px solid #042c71;
  border-radius: 50%;
  transition: all 0.3s;
}

.logo:hover {
  height: 7.4rem;
}

.main-nav-list {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 3.2rem;
}

.main-nav-link {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  font-size: 1.8rem;
  transition: all 0.3s;
}

.main-nav-link:hover {
  transform: translateY(3px);
  color: #021a44;
}

.main-nav-link.nav-warning {
  padding: 1.2rem 2.4rem;
  border-radius: 9px;
  color: #fff;
  background-color: #042c71;
}

.main-nav-link.nav-warning:hover {
  transform: translateY(3px);
  background-color: #021a44;
}

.btn-mobile-nav {
  border: none;
  background: none;
  cursor: pointer;
  display: none;
}

.icon-mobile-nav {
  height: 4.8rem;
  width: 4.8rem;
  color: #333;
}

.icon-mobile-nav[name="close-outline"] {
  display: none;
}

.section-hero {
  background-color: #8296b8;
  padding: 4.8rem 0 9.6rem 0;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.heading-primary {
  font-size: 5.2rem;
  font-weight: 700;
  line-height: 1.05;
  color: #333;
  letter-spacing: -0.4px;
  margin-bottom: 3.2rem;
}

.hero-description {
  font-size: 2rem;
  line-height: 1.4;
  margin-bottom: 4.8rem;
}

/* HERO BUTTONS */
.btn {
  display: inline-block;
  font-size: 2rem;
  padding: 1.6rem 3.2rem;
  text-decoration: none;
  border-radius: 1rem;

  border: none;
  cursor: pointer;
  transition: all 0.3s;
}

.btn--full {
  color: #fff;
  background-color: #042c71;
}

.btn--full:hover {
  background-color: #021a44;
}

.btn--outline {
  background-color: #fff;
  color: #042c71;
}

.btn--outline:hover {
  background-color: #8296b8;
  box-shadow: inset 0 0 0 3px #fff;
}

.margin-right-sm {
  margin-right: 1.6rem !important;
}

.hero-img {
  width: 100%;
}

#Question {
  animation: questionAnimation 2s linear infinite;
  transform-origin: center;
}

@keyframes questionAnimation {
  0%,
  100% {
    transform: rotateZ(0deg);
  }
  25% {
    transform: rotateZ(15deg);
  }
  75% {
    transform: rotateZ(-15deg);
  }
}

/* REGISTER AND LOGIN PAGE */

.section-randl {
  padding: 4.8rem 0 12.8rem 0;
}

.randl {
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  background-image: linear-gradient(to right, #8296b8, #36568d);
  overflow: hidden;
}

.randl-text-box {
  padding: 4.8rem 6.4rem 6.4rem 6.4rem;
  color: #042c71;
}

.register-img-box {
  background-image: linear-gradient(
      to right,
      rgba(130, 150, 184, 0.414),
      rgba(54, 54, 141, 0.389)
    ),
    url("../imgs/register.png");
  background-size: cover;
  background-position: center;
}

.login-img-box {
  background-image: linear-gradient(
      to right,
      rgba(130, 150, 184, 0.414),
      rgba(54, 54, 141, 0.389)
    ),
    url("../imgs/login.png");
  background-size: cover;
  background-position: center;
}

.randl-form {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 3.2rem;
}

.randl-form label {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.randl-form input,
.randl-form select {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: #fff;
  border: none;
  background-color: #36568d;
  border-radius: 0.9rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.randl-form input::placeholder {
  color: #aaa;
}

.btn--form {
  background-color: #36568d;
  color: #fff;
  align-self: end;
  margin-top: 3.6rem;
  padding: 1.2rem;
}

.btn--form:hover {
  background-color: #fff;
  color: #042c71;
}

/* ROADMAP */

.section-how {
  padding: 9.6rem 0;
}

.grid {
  display: grid;
  column-gap: 6.4rem;
  row-gap: 2rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--center-v {
  align-items: center;
}

.subheading {
  display: block;
  font-size: 1.6rem;
  font-weight: 500;
  color: #36568d;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  letter-spacing: 0.75px;
}

.heading-secondary {
  font-size: 4.4rem;
  line-height: 1.2;
  margin-bottom: 8rem;
}

.heading-tertiary {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 3.2rem;
}

.step-img-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.step-img-box::before {
  content: "";
  display: block;
  border-radius: 50%;

  position: absolute;
  top: 50%;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.step-img-box::before {
  width: 80%;
  padding-bottom: 80%;
  background-color: #8296b8;
}

.step-img {
  width: 60%;
}

#circle,
#tick {
  animation: turnAround 4s linear infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes turnAround {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

.step-number {
  font-size: 8.6rem;
  font-weight: 600;
  color: #ddd;
  margin-bottom: 1.2rem;
}

.step-description {
  font-size: 1.8rem;
  line-height: 1.8;
}

/* TEAM */

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.section-team {
  padding: 9.6rem 0;
  background-color: #8296b8;
  padding-top: 0;
}

.padding-top-sm {
  padding-top: 1.6rem;
}

.member {
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.75);
  border-radius: 1.2rem;
  background-color: #fff;
  overflow: hidden;
  transition: all 0.5s;
}

.member:hover {
  transform: translateY(-10px);
  box-shadow: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.06);
}

.member-content {
  padding: 3.2rem 4.8rem 4.8rem 4.8rem;
}

.member-name {
  margin-bottom: 1.2rem;
  display: flex;
  gap: 0.4rem;
}

.name {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #fff;
  background-color: #8296b8;
  border-radius: 10rem;
}

.member-attributes {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.member-attribute {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
}

.member img {
  width: 100%;
}

.member ion-icon {
  text-align: center;
  height: 2.4rem;
  width: 2.4rem;
  color: #042c71;
}

.btn--team {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  border: 2px solid #8296b8;
  color: #333;
}

.btn--team:hover {
  background-color: #8296b8;
  color: #fff;
}

.all-members {
  margin-top: 4.8rem;
  text-align: center;
  font-size: 2.4rem;
}

.all-members a {
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
}

.all-members a:hover {
  color: #000;
}

.all-team {
  margin-top: 9.6rem;
  margin-bottom: 9.6rem;
}

/* FEATURE */

.feature-icon {
  color: #042c71;
  height: 3.2rem;
  width: 3.2rem;
  margin-bottom: 3.2rem;
  background-color: #9babc6;
  padding: 1.6rem;
  border-radius: 50%;
}

.feature-title {
  font-size: 2.4rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 1.6rem;
}

.feature-text {
  font-size: 1.6rem;
  line-height: 1.8;
  margin-bottom: 9.6rem;
}

/* FOOTER */

.footer {
  width: 100%;
  border-top: 1px solid #333;
  padding: 4.5rem 5rem;
}

.social-list,
.title-list,
.footer-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.6rem;
  gap: 2.4rem;
  list-style: none;
}

.social-icon {
  font-size: 2.4rem;
  color: #042c71;
}

.footer-title a {
  font-size: 2rem;
  color: #042c71;
  text-decoration: none;
}

.footer-text {
  font-size: 2rem;
  font-weight: 500;
  color: #042c71;
}
